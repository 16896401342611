import React, { useState } from "react";
import axios from "axios";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { app } from "../../server/firebase";

// Modal Component
const LoginModal = ({ isOpen, onClose, onLoginSuccess }) => {
  // State variables for form inputs and feedback messages
  const auth = getAuth(app);
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Function to handle form submission
  //   const handleLogin = async (e) => {
  //     e.preventDefault();
  //     setLoading(true);
  //     setError("");
  //     try {
  //       const response = await axios.post(
  //         "https://us-central1-mirror-web-91f40.cloudfunctions.net/loginUser",
  //         { email: email, password: password }
  //       );
  //       const { token } = response.data;
  //       console.log(token);
  //       const userCredential = await authenticateWithCustomToken(token);
  //       onLoginSuccess(userCredential);
  //       onClose();
  //     } catch (err) {
  //       setError("Invalid email or password. Please try again.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  // Function to authenticate with the custom token using Firebase Client SDK
  //   const authenticateWithCustomToken = async (customToken) => {
  //     const auth = getAuth();
  //     const userCredential = await signInWithCustomToken(auth, customToken);
  //     return userCredential;
  //   };
  const loginUser = async (e) => {
    e.preventDefault();
    try {
      // Sign in the user using Firebase Client SDK
      console.log(email);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Get the ID token
      const idToken = await userCredential.user.getIdToken();
      console.log(idToken);
      // Send the ID token to the backend
      const response = await axios.post(
        "https://us-central1-mirror-web-91f40.cloudfunctions.net/loginUser",
        {
          idToken,
        }
      );
      localStorage.setItem("authTokenNew", response.data.token);
      localStorage.setItem("idToken", idToken);
      localStorage.setItem("uid", response.data.uid);
      history("/myvideo");
      onClose();
      console.log("Login successful:", response.data);
    } catch (error) {
      console.log("Error logging in:", error);
      console.error("Error logging in:", error);
    }
  };

  // Render nothing if modal is not open
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <button
          onClick={onClose}
          className="close-button"
          style={{ cursor: "pointer", fontSize: "20px" }}
        >
          X
        </button>
        <h2 style={{ marginBottom: "30px" }}>登录</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={loginUser}>
          <div style={{ marginBottom: "10px" }}>
            <label>电邮:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ width: "100%", height: "30px", padding: "3px 10px" }}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            <label>密码:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{ width: "100%", height: "30px", padding: "3px 10px" }}
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            style={{ cursor: "pointer", width: "60%", height: "30px" }}
          >
            {loading ? "正在登录..." : "登录"}
          </button>
        </form>
      </div>
      <style>{`
        .modal-backdrop {
            z-Index:999;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 5px;
          text-align:center;
        }
        .modal {
          background: white;
          padding: 20px;
          border-radius: 8px;
          width: 300px;
          height:300px;
          position:relative;

          background-color: #666;
        }
        .close-button {
            position :absolute;
            top:0;right:0;
          margin-top: 10px;
          margin-right:10px;
          background: transparent;
          border-width: 0px;
          cursor: pointer:
          width:50px;
        }
        .error {
          color: red;
        }
      `}</style>
    </div>
  );
};

export default LoginModal;
