import "./footer.css";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
const Bottom = () => {
  return (
    <div
      style={{
        minHeight: "50px",
        padding: "20px 30px",
        textAlign: "left",
        display: "flex",
        alignItems: "center",
        background: "#000",
        color: "#6f6f6f",
        border: "1px solid #222",
      }}
    >
      <div>
        <div style={{ marginBottom: "20px" }}>© 2024 – All rights reserved</div>
      </div>
    </div>
  );
};
export default Bottom;
