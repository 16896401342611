import React, { useEffect, useState, useContext } from "react";
import { Layout } from "antd";
import "./publicLayout.css";
import CSHeader from "../Header/header";
import CSBottom from "../Footer/bottom";
import { useLocation } from "react-router-dom";
// import { Context } from "../../context/index";

export default function PublicLayout({ children }) {
  const { Content } = Layout;
  const location = useLocation();

  // Check if the current URL starts with "/play"
  const isPlayPage = location.pathname.endsWith("/video");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  return (
    <>
      <Layout
        className="Layout"
        style={{ background: isPlayPage ? "#333" : "#000" }}
      >
        <CSHeader />
        <Content
          style={{
            margin: "0 auto",
            height: "auto",
            minHeight: "100%",
            background: isPlayPage ? "#333" : "#000",
            maxWidth: "1280px",
            width: "100%",
            padding: "100px 5px",
            //   background: "#000",
            // paddingBottom: "100px",
            // zIndex: 1,
          }}
        >
          {children}
        </Content>
        <CSBottom style={{ zIndex: 9999 }} />{" "}
      </Layout>
    </>
  );
}
