import "./App.css";
import PublicLayout from "./component/Layout/publicLayout";
import { BrowserRouter as Router } from "react-router-dom";
import CustomRoute from "./Route.jsx";
import { getVideoList } from "./context/utility.jsx";
import { useContext, useEffect } from "react";
import { Context } from "./context/index.jsx";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "./server/firebase.js";

function App() {
  const { state, dispatch } = useContext(Context);
  const { videoList } = state;

  const auth = getAuth(app);

  useEffect(() => {
    async function fetchData() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user
            .getIdToken(true)
            .then((idToken) => {
              localStorage.setItem("idToken", idToken);
              // console.log("New ID Token:", idToken);
              // Use the new token for authenticated requests
            })
            .catch((error) => {
              console.error("Error getting new ID token:", error);
            });
        }
      });
      // console.log("fetch");
      const result = await getVideoList();
      // console.log(result);
      dispatch({
        type: "videoList",
        payload: result,
      });
    }
    fetchData();
  }, []);

  return (
    <>
      <Router>
        <PublicLayout>
          <CustomRoute />
        </PublicLayout>
      </Router>
    </>
  );
}

export default App;
