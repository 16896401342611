import "./header.css";
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWindowSize } from "../../context/utility";
import NavbarM from "./navbar-M";
import LoginModal from "../Login/loginModal";

// Set timeout vars.
let warningTimer;
let timeoutTimer;
export let language = "CN";

export let timerStarted = false;
// Show idle timeout warning dialog.

// Reset timers.
export function ResetTimers(timoutWarning = 840000, timoutNow = 900000) {
  clearTimeout(warningTimer);
  clearTimeout(timeoutTimer);
  //alert(`Reset a timer: warning:${warningTimer} warning:${timeoutTimer} cleared`);
  StartTimers(timoutWarning, (timoutNow = 900000));
}

// Start timers.
export function StartTimers(timoutWarning = 840000, timoutNow = 900000) {
  //alert(`Started a timer: ${timoutNow/1000} secs`);
  timerStarted = true;
}

if (!timerStarted) {
  timerStarted = true;
  StartTimers();
}

const logoPath = "./logo128.png";

const Header = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [width, height] = useWindowSize();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  function handleChange(lang) {
    //false = CN  true = EN \
    setLoading(true);
    window.location.reload();
    if (lang) {
      language = "EN";
    } else {
      language = "CN";
    }
    localStorage.setItem("lang", JSON.stringify(language));
    setLoading(false);
  }

  // Function to handle successful login
  const handleLoginSuccess = (userCredential) => {
    // Store the token in local storage
    userCredential.user.getIdToken().then((token) => {
      localStorage.setItem("authTokenNew", token);
      console.log(token);
    });
    setIsLoginModalOpen(false);
  };
  // Function to handle click event
  const handleLinkClick = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    const token = localStorage.getItem("authTokenNew");
    console.log(token);
    if (!token) {
      setIsLoginModalOpen(true); // Open the login modal if no token is found
    } else {
      history("/myvideo"); // Navigate to /myvideo if token exists
    }
  };

  return (
    <div
      style={{
        width: "100%",
        position: "fixed",
        background: "#000",
        zIndex: 100,
      }}
    >
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLoginSuccess={handleLoginSuccess}
      />
      {width > 850 ? (
        <div
          style={{
            display: "flex",
            maxWidth: "1280px",
            padding: "10px 1%",
            margin: "0 auto",
          }}
        >
          <img
            onClick={() => history("/")}
            src="./ui/logo.png"
            alt="logo"
            style={{ width: "50px", height: "50px", cursor: "pointer" }}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              padding: "0px 5%",
              justifyContent: "right",
              alignItems: "center",
              gap: "5%",
              fontSize: "22px",
            }}
          >
            <div>
              <Link to={"/"} style={{ textDecoration: "none", color: "#fff" }}>
                <div className="video_bn">
                  <div id="VIDEOS">
                    <span>首页</span>
                  </div>
                </div>
              </Link>
            </div>
            <div>
              <Link
                to={"https://lan-gui-fang.com/"}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="video_bn">
                  <div id="VIDEOS">
                    <span>兰桂坊</span>
                  </div>
                </div>
              </Link>
            </div>

            {/* <Link
              to={"/link"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn">
                <div id="VIDEOS">
                  <span>TG群</span>
                </div>
              </div>
            </Link>
            <Link
              to={"/link"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn">
                <div id="VIDEOS">
                  <span>电影线上看</span>
                </div>
              </div>
            </Link>
            <Link
              to={"/link"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn">
                <div id="VIDEOS">
                  <span>绅士游戏</span>
                </div>
              </div>
            </Link>
            <Link
              to={"/link"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn">
                <div id="VIDEOS">
                  <span>H漫画</span>
                </div>
              </div>
            </Link> */}
            <Link
              to={"/link"}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn">
                <div id="VIDEOS">
                  <span>更多</span>
                </div>
              </div>
            </Link>
            <a
              href="/myvideo"
              onClick={handleLinkClick}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className="video_bn">
                <div id="VIDEOS">
                  <span>收藏</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      ) : (
        <NavbarM setIsLoginModalOpen={setIsLoginModalOpen} history={history} />
      )}
    </div>
  );
};
export default Header;
