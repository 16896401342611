import { useEffect, useState } from "react";
import axios from "axios";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
}
function isJavaScriptDate(obj) {
  return obj instanceof Date;
}
function convertTimestampForSort(timestamp, symbol = "-", type = "number") {
  let date = new Date();

  if (
    typeof timestamp == "number" ||
    !timestamp ||
    typeof timestamp == "string"
  ) {
    let mm = date?.getMonth() + 1;
    let dd = date?.getDate();
    let yyyy = date?.getFullYear();

    date = yyyy + symbol + mm + symbol + dd;
    return date;
  }
  function firebaseTimestampToDate(firebaseTimestamp) {
    if (!firebaseTimestamp) {
      throw new Error("Invalid Firebase timestamp.");
    }

    // Check for the presence of _seconds or seconds
    const seconds = firebaseTimestamp._seconds || firebaseTimestamp.seconds;
    const nanoseconds =
      firebaseTimestamp._nanoseconds || firebaseTimestamp.nanoseconds;

    if (seconds === undefined || nanoseconds === undefined) {
      if (nanoseconds === undefined) {
        return new Date(seconds * 1000);
      } else {
        return new Date();
      }

      // throw new Error("Invalid Firebase timestamp format.");
    }

    // Convert to milliseconds and create a Date object
    return new Date(seconds * 1000 + nanoseconds / 1000000);
  }

  if (isJavaScriptDate(timestamp)) {
    date = new Date(timestamp);
  } else {
    date = firebaseTimestampToDate(timestamp);
  }

  let mm = date?.getMonth() + 1;
  let dd = date?.getDate();
  let yyyy = date?.getFullYear();
  if (type === "long") {
    const monthNamesFull = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNamesFull[date.getMonth()];
    date = `${dd} ${monthName} ${yyyy}`;
  } else if (type === "short") {
    const monthNamesFull = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNamesFull[date.getMonth()];
    date = `${dd} ${monthName}, ${yyyy}`;
  } else {
    date = `${yyyy}${symbol}${mm}${symbol}${dd}`;
  }
  return date;
}
async function getVideoList() {
  try {
    const result = await axios.get(
      `https://us-central1-mirror-web-91f40.cloudfunctions.net/getVideoData`
    );
    return result.data;
  } catch (error) {
    console.error("Error ", error);
    console.log("Error", error);
    return [];
  }
}
async function sendFeedback(regInfo, type) {
  try {
    const response = await axios.post(
      `https://us-central1-spring-firm-studios-iii.cloudfunctions.net/sendFeedback?type=${type}`,
      regInfo
    );
    return "success";
  } catch (error) {
    console.log("error");
    return "error";
  }
}
function formatTimeFromSeconds(totalSeconds) {
  // Check if total seconds are less than 60
  //   if (totalSeconds < 60) {
  //     return `${Math.floor(totalSeconds)}`; // Just return seconds with 's' suffix if less than a minute
  //   }

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  // Build time string
  const hoursStr = hours > 0 ? `${hours}:` : "";
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

  // Concatenate time parts, skipping hours if it's 0
  return `${hoursStr}${minutesStr}:${secondsStr}`;
}

export {
  useWindowSize,
  getVideoList,
  sendFeedback,
  convertTimestampForSort,
  formatTimeFromSeconds,
};
